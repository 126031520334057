import React, { Component, Fragment, RefObject } from 'react';
import { graphql } from 'gatsby';
import { withTranslation, WithTranslation } from 'gatsby-plugin-react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import axios, { AxiosError, AxiosResponse } from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Cookies from 'js-cookie';

/** Components */
import Seo from '@components/seo';
import MainContainer from '@components/main.container';

/** Models */
import { BackResponse } from '@models/back-response.model';

/** Root */
import { asyncWrap } from '@root/util';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const minLengthPassword = 6;
const maxLengthPassword = 48;
const barColors = ['#f8f9fa', '#dc3545', '#fd7e14', '#0d6efd', '#198754'];
const registerErrorPrefix = 'register.error';
const timeoutToast = 4000;
const defaultToastClass = [
  'toast',
  'text-center',
  'text-white',
  'position-absolute',
  'bottom-0',
  'end-0',
  'mb-2',
  'me-2',
].join(' ');

type State = {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  passwordScore: number;
  scoreWords: string[];
  showError: boolean;
  loading: boolean;
  referralCode: string | undefined;
  giftCode: string | undefined;
  toastText: string;
  toastClass: string;
  tokenCaptcha: string | undefined;
  legalTerms: boolean;
};

class RegisterComponent extends Component<WithTranslation, State> {
  private readonly referralUrlParam = 'referralCode';
  private readonly giftCodeUrlParam = 'giftCode';
  private readonly hCaptchaRef: RefObject<HCaptcha>;

  constructor(props: Readonly<WithTranslation>) {
    super(props);

    this.hCaptchaRef = React.createRef<HCaptcha>();
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      passwordScore: 0,
      scoreWords: [],
      showError: false,
      loading: false,
      toastText: '',
      toastClass: defaultToastClass,
      referralCode: undefined,
      giftCode: undefined,
      tokenCaptcha: undefined,
      legalTerms: false,
    };
  }

  componentDidMount(): void {
    const { search } = window.location;
    const searchParameters = new URLSearchParams(search);
    const { t } = this.props;
    const initScoreWords = [
      t('register.passwordStrength.weak'),
      t('register.passwordStrength.weak'),
      t('register.passwordStrength.ok'),
      t('register.passwordStrength.strong'),
      t('register.passwordStrength.veryStrong'),
    ];

    let referralCode = undefined;
    let giftCode = undefined;

    if (searchParameters.has(this.referralUrlParam)) {
      referralCode = searchParameters.get(this.referralUrlParam) as string;
      Cookies.set(this.referralUrlParam, referralCode);
    } else if (Cookies.get(this.referralUrlParam)) {
      referralCode = Cookies.get(this.referralUrlParam);
    }

    if (searchParameters.has(this.giftCodeUrlParam)) {
      giftCode = searchParameters.get(this.giftCodeUrlParam) as string;
    }

    this.setState({ scoreWords: initScoreWords, referralCode, giftCode });
  }

  private canSubmit(): string[] {
    const { t } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      passwordScore,
      tokenCaptcha,
      legalTerms,
    } = this.state;

    const errors = [];

    if (!firstName.length) {
      errors.push(t(`${registerErrorPrefix}.firstName`));
    }

    if (!lastName.length) {
      errors.push(t(`${registerErrorPrefix}.lastName`));
    }

    if (!email.length) {
      errors.push(t(`${registerErrorPrefix}.email`));
    }

    if (!password.length) {
      errors.push(t(`${registerErrorPrefix}.password`));
    } else {
      if (
        password.length < minLengthPassword ||
        password.length > maxLengthPassword
      ) {
        errors.push(
          t(`${registerErrorPrefix}.passwordLength`, {
            min: minLengthPassword,
            max: maxLengthPassword,
          }),
        );
      }

      if (!passwordScore) {
        errors.push(t(`${registerErrorPrefix}.passwordStrength`));
      }
    }

    if (!tokenCaptcha) {
      errors.push(t(`${registerErrorPrefix}.captcha`));
    }

    if (!legalTerms) {
      errors.push(t(`${registerErrorPrefix}.legalTerms`));
    }

    return errors;
  }

  private async submit() {
    this.setState({ showError: false });
    const resultCanSubmit = this.canSubmit();

    if (resultCanSubmit.length) {
      this.setState({ showError: true });

      return;
    }

    const {
      firstName,
      lastName,
      email,
      password,
      referralCode,
      tokenCaptcha,
      legalTerms,
      giftCode,
    } = this.state;
    const data: any = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      password,
      tokenCaptcha,
      legalTerms,
    };

    if (referralCode) {
      data.referralCode = referralCode;
    }

    if (giftCode) {
      data.giftCode = giftCode;
    }

    this.setState({ loading: true });
    const { t } = this.props;
    const requestRegister = await asyncWrap<AxiosResponse, AxiosError>(
      axios({
        method: 'POST',
        url: `${process.env.GATSBY_API_URL}/register`,
        data,
      }),
    );

    this.setState({ loading: false });

    if (requestRegister.error) {
      this.hCaptchaRef.current?.resetCaptcha();
      const error = requestRegister.error as any;

      if (error.isAxiosError) {
        this.showToast(error.response?.data.message, true);
      } else {
        this.showToast(t('global.errors.somethingWrong'), true);
      }

      return;
    }

    const response = requestRegister.result?.data as BackResponse;

    if (response.result) {
      this.showToast(response.message, false);
      setTimeout(() => {
        window.location.href = process.env.GATSBY_APP_URL as string;
      }, 800);
    } else {
      this.showToast(response.message, true);
    }
  }

  private showToast(message: string, isError: boolean) {
    const { toastClass } = this.state;
    const classList = toastClass.split(' ');

    classList.push(isError ? 'bg-danger' : 'bg-success', 'd-flex', 'show');

    this.setState(
      { toastText: message, toastClass: classList.join(' ') },
      () => {
        setTimeout(() => {
          this.setState({ toastClass: defaultToastClass });
        }, timeoutToast);
      },
    );
  }

  render(): JSX.Element {
    const { t } = this.props;
    const resultCanSubmit = this.canSubmit();
    const {
      firstName,
      lastName,
      email,
      password,
      referralCode,
      scoreWords,
      showError,
      toastClass,
      toastText,
      loading,
      legalTerms,
      giftCode,
    } = this.state;

    return (
      <Fragment>
        <Seo />
        <MainContainer classNameContainer="bg-primary register-section h-100">
          <h1 className="text-white text-center text-uppercase fw-bold mt-5 pt-5 mb-3">
            {t('navbar.register')}
          </h1>
          <div className="m-0 row container-register mx-auto">
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  id="inputFirstName"
                  type="text"
                  className="form-control"
                  placeholder={t('register.firstName')}
                  aria-label={t('register.firstName')}
                  value={firstName}
                  onChange={ev => this.setState({ firstName: ev.target.value })}
                />
                <label className="text-muted" htmlFor="inputFirstName">
                  {t('register.firstName')}
                </label>
              </div>
            </div>
            <div className="col-sm-6 mt-3 mt-sm-0">
              <div className="form-floating">
                <input
                  id="inputLastName"
                  type="text"
                  className="form-control"
                  placeholder={t('register.lastName')}
                  aria-label={t('register.lastName')}
                  autoComplete="off"
                  value={lastName}
                  onChange={ev => this.setState({ lastName: ev.target.value })}
                />
                <label className="text-muted" htmlFor="inputLastName">
                  {t('register.lastName')}
                </label>
              </div>
            </div>
            <div className="col-sm-6 mt-3">
              <div className="form-floating">
                <input
                  id="inputEmail"
                  type="email"
                  className="form-control"
                  placeholder={t('register.email')}
                  aria-label={t('register.email')}
                  autoComplete="off"
                  value={email}
                  onChange={ev => this.setState({ email: ev.target.value })}
                />
                <label className="text-muted" htmlFor="inputEmail">
                  {t('register.email')}
                </label>
              </div>
            </div>
            <div className="col-sm-6 mt-3">
              <div className="form-floating">
                <input
                  id="inputPassword"
                  type="password"
                  className="form-control"
                  placeholder={t('register.password')}
                  aria-label={t('register.password')}
                  autoComplete="off"
                  value={password}
                  onChange={ev => this.setState({ password: ev.target.value })}
                />
                <label className="text-muted" htmlFor="inputPassword">
                  {t('register.password')}
                </label>
                <PasswordStrengthBar
                  minLength={minLengthPassword}
                  password={password}
                  className="password-strength position-absolute w-100"
                  scoreWordClassName="fw-bold text-white"
                  barColors={barColors}
                  scoreWords={scoreWords}
                  shortScoreWord={t('register.passwordStrength.tooShort')}
                  onChangeScore={score =>
                    this.setState({ passwordScore: score })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6 mt-5 mt-sm-3">
              <div className="form-floating mt-4">
                <input
                  id="inputReferralCode"
                  type="text"
                  className="form-control"
                  placeholder={t('register.referralCode')}
                  aria-label={t('register.referralCode')}
                  autoComplete="off"
                  value={referralCode}
                  onChange={ev =>
                    this.setState({ referralCode: ev.target.value })
                  }
                />
                <label className="text-muted" htmlFor="inputReferralCode">
                  {t('register.referralCode')}
                </label>
              </div>
            </div>
            <div className="col-sm-6 mt-5 mt-sm-3">
              <div className="form-floating mt-4">
                <input
                  id="inputGiftCode"
                  type="text"
                  className="form-control"
                  placeholder={t('register.giftCode')}
                  aria-label={t('register.giftCode')}
                  autoComplete="off"
                  value={giftCode}
                  onChange={ev => this.setState({ giftCode: ev.target.value })}
                />
                <label className="text-muted" htmlFor="inputGiftCode">
                  {t('register.giftCode')}
                </label>
              </div>
            </div>
            <div className="col-sm-12 d-flex justify-content-center mt-3">
              <HCaptcha
                ref={this.hCaptchaRef}
                sitekey={process.env.GATSBY_HCAPTCHA_SITEKEY as string}
                onVerify={(token: string) =>
                  this.setState({ tokenCaptcha: token })
                }
              />
            </div>
            <div className="col-sm-12 d-flex justify-content-center mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="checkTermsOfUse"
                  checked={legalTerms}
                  onChange={() => this.setState({ legalTerms: !legalTerms })}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="checkTermsOfUse"
                >
                  {t('register.acceptTermsOfUse')}
                </label>
              </div>
            </div>
            {resultCanSubmit.length && showError ? (
              <section className="col-sm-12">
                <div className="alert alert-danger mt-3 text-center">
                  <ul className="list-unstyled mb-0">
                    {resultCanSubmit.map((errStr: string) => (
                      <li key={errStr.toLowerCase()}>{errStr}</li>
                    ))}
                  </ul>
                </div>
              </section>
            ) : null}
            <div className="col-sm-12 mt-3 pb-5">
              <button
                type="submit"
                className="btn btn-secondary btn-lg float-end"
                disabled={loading}
                onClick={() => this.submit()}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm align-middle me-1"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                <span className="ms-1">{t('navbar.register')}</span>
              </button>
            </div>
          </div>
          <div
            className={toastClass}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body w-100">{toastText}</div>
          </div>
        </MainContainer>
      </Fragment>
    );
  }
}

const Register = withTranslation()(RegisterComponent);

export default Register;
